ion-content ion-toolbar {
  --background: translucent;
}

ion-content {
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto !important;
}

/*html, body {*/
/*  overscroll-behavior: none;*/
/*  overflow-x: hidden;*/
/*  overflow-y: hidden;*/
/*}*/

/*ion-app {*/
/*  position: fixed;*/
/*  top: 0px;*/
/*  bottom: 0px;*/
/*  left: 0px;*/
/*  right: 0px;*/
/*  overscroll-behavior: contain;*/
/*}*/


ion-slide {
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;

}

.score-holder {
  display: none;
}
.score {
  position: fixed;
  bottom: 50%;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  white-space: nowrap;
  padding-bottom: 5px;
  z-index: 20;
  text-align: center;
  font-size: 9pt;
  font-weight: bold;
}
.score-counter {
  text-align: center;
}
.highscore {
  position: absolute; top: -14px;
  left: 0px; right: 0px;
  text-align: center;
  opacity: .3;
}
.score-prize {
  padding-left: 20px;
  opacity: .3;
}
.score-counter {
  position: absolute; top: 9px;
  left: 0px; right: 0px;
  text-align: center;
  opacity: .5;
  font-size: 11pt;
}
.score-prize-bar-holder {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 6px;
  margin-top: -3px;
  z-index: 25;
}
.score-prize-bar {
  margin-left: auto;
  margin-right: auto;
  background: #000;
  height: 3px;
  opacity: .5;

}



.slidesTop, .slidesBottom {
  height: 50%;
  background-color: #000;
}

.slide-yellow {
  background-color: #ffe800;
}
.slide-green {
  background-color: #8dc548;
}
.slide-purple {
  background-color: #a2238e;
}
.slide-red {
  background-color: #ee4037;
}

ion-slide img {
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}
ion-slide.slide-sound-playing img {
  animation: shake 1s ease-in-out;
  /*transform: scale(1.1);*/
  /*transform: scale(1.1);*/
}

@keyframes shake {
  10% {
    transform: scale(1.1);
  }
  25%{
    transform: scale(1.1) rotateZ(-5deg);
  }


  75%{
    transform: scale(1.1) rotateZ(5deg);
  }

  90% {
    transform: scale(1.1);
  }


}

@keyframes grow {
  50% {

  }
}